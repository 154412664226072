import { createWebHashHistory, createRouter } from 'vue-router'
import routes from './routes'

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token")
    const role = localStorage.getItem("role")

    if (to.path !== '/auth/sign-in' && from.path !== '/auth/sign-in' && !token) {
        next('/auth/sign-in')
        return
    }

    if (to.path === '/' && from.path === '/' && !token) {
        next('/auth/sign-in')
        return
    }

    if (to.path === '/' && from.path === '/' && token) {
        switch (role) {
            case 'ADMIN':
                next('/cabinet/admin/appeals')
                break
            case 'OPERATOR':
                next('/cabinet/operator/appeals')
                break
            case 'CATEGORY':
                next('/cabinet/category/appeals')
                break
            case 'REGION':
                next('/cabinet/region/appeals')
                break
            case 'DISTRICT':
                next('/cabinet/district/appeals')
                break
            case 'MINISTRY':
                next('/cabinet/ministry/appeals')
                break


        }
        return
    }

    next()
})

export default router