const routes = [
    {
        path: '/auth',
        component: () => import('@/layouts/auth'),
        name: 'auth-layout',
        children: [
            {
                path: 'sign-in',
                component: () => import('@/pages/auth/sign-in')
            }
        ]
    },
    {
        path: '/cabinet',
        component: () => import('@/layouts/default'),
        name: 'default-layout',
        children: [
            {
                path: 'admin',
                name: 'cabinet-admin',
                children: [
                    {
                        path: 'home',
                        component: () => import('@/pages/cabinet/admin'),
                        name: 'cabinet-admin-home'
                    },
                    {
                        path: 'users',
                        component: () => import('@/pages/cabinet/admin/users'),
                        name: 'cabinet-admin-users'
                    },
                    {
                        path: 'users/create',
                        component: () => import('@/pages/cabinet/admin/users/Create'),
                        name: 'cabinet-admin-users-create'
                    },
                    {
                        path: 'users/update/:id',
                        component: () => import('@/pages/cabinet/admin/users/update/[id]'),
                        name: 'cabinet-admin-users-update'
                    },
                    {
                        path: 'statistics',
                        component: () => import('@/pages/cabinet/admin/statistics'),
                        name: 'cabinet-admin-statistics',
                        children: [
                            {
                                path: 'ministry',
                                component: () => import('@/pages/cabinet/admin/statistics/ministry'),
                                name: 'cabinet-admin-statistics-ministry',
                            },
                            {
                                path: 'category',
                                component: () => import('@/pages/cabinet/admin/statistics/category'),
                                name: 'cabinet-admin-statistics-category',
                            },
                            {
                                path: 'sub-category',
                                component: () => import('@/pages/cabinet/admin/statistics/sub-category'),
                                name: 'cabinet-admin-statistics-sub-category',
                            },
                            {
                                path: 'region',
                                component: () => import('@/pages/cabinet/admin/statistics/region'),
                                name: 'cabinet-admin-statistics-region',
                            },
                            {
                                path: 'district',
                                component: () => import('@/pages/cabinet/admin/statistics/district'),
                                name: 'cabinet-admin-statistics-district',
                            },
                            {
                                path: 'appeal-region',
                                component: () => import('@/pages/cabinet/admin/statistics/appeal-region'),
                                name: 'cabinet-admin-statistics-appeal-region',
                            },
                            {
                                path: 'appeal-district',
                                component: () => import('@/pages/cabinet/admin/statistics/appeal-district'),
                                name: 'cabinet-admin-statistics-appeal-district',
                            }
                        ]
                    },
                    {
                        path: 'map',
                        component: () => import('@/pages/cabinet/admin/map'),
                        name: 'cabinet-admin-map'
                    },
                    {
                        path: 'appeals',
                        component: () => import('@/pages/cabinet/admin/appeals'),
                        name: 'cabinet-admin-appeals',
                    },
                    {
                        path: 'appeals/review/:id',
                        component: () => import('@/pages/cabinet/admin/appeals/review/[id]'),
                        name: 'cabinet-admin-appeals-review',
                    },
                    {
                        path: 'integ-appeals',
                        component: () => import('@/pages/cabinet/admin/integ-appeals'),
                        name: 'cabinet-admin-integ-appeals',
                    },
                    {
                        path: 'integ-appeals/review/:id',
                        component: () => import('@/pages/cabinet/admin/integ-appeals/review/[id]'),
                        name: 'cabinet-admin-integ-appeals-review',
                    },
                    {
                        path: 'settings',
                        children: [
                            {
                                path: 'ministries',
                                component: () => import('@/pages/cabinet/admin/settings/ministries'),
                                name: 'cabinet-admin-settings-ministries'
                            },
                            {
                                path: 'ministry-groups',
                                component: () => import('@/pages/cabinet/admin/settings/ministry-groups'),
                                name: 'cabinet-admin-settings-ministry-groups'
                            },
                            {
                                path: 'organization-categories',
                                component: () => import('@/pages/cabinet/admin/settings/organization-categories'),
                                name: 'cabinet-admin-settings-organization-categories'
                            },
                            {
                                path: 'appeal-type',
                                component: () => import('@/pages/cabinet/admin/settings/appeal-type'),
                                name: 'cabinet-admin-settings-organization-appeal-type'
                            },
                            {
                                path: 'activity-type',
                                component: () => import('@/pages/cabinet/admin/settings/activity-type'),
                                name: 'cabinet-admin-settings-organization-activity-type'
                            },
                            {
                                path: 'appellant-type',
                                component: () => import('@/pages/cabinet/admin/settings/appellant-type'),
                                name: 'cabinet-admin-settings-organization-appellant-type'
                            },
                            {
                                path: 'countries',
                                component: () => import('@/pages/cabinet/admin/settings/countries'),
                                name: 'cabinet-admin-settings-countries'
                            },
                            {
                                path: 'regions',
                                component: () => import('@/pages/cabinet/admin/settings/regions'),
                                name: 'cabinet-admin-settings-regions'
                            },
                            {
                                path: 'districts',
                                component: () => import('@/pages/cabinet/admin/settings/districts'),
                                name: 'cabinet-admin-settings-districts'
                            },
                            {
                                path: 'sub-categories',
                                component: () => import('@/pages/cabinet/admin/settings/sub-categories'),
                                name: 'cabinet-admin-settings-sub-categories',
                            },
                            {
                                path: 'categories',
                                component: () => import('@/pages/cabinet/admin/settings/categories'),
                                name: 'cabinet-admin-settings-categories'
                            },
                        ]
                    }
                ]
            },
            {
                path: 'operator',
                name: 'cabinet-operator',
                children: [
                    {
                        path: 'appeals',
                        component: () => import('@/pages/cabinet/operator/appeals'),
                        name: 'cabinet-operator-appeals'
                    },
                    {
                        path: 'appeals/create',
                        component: () => import('@/pages/cabinet/operator/appeals/Create'),
                        name: 'cabinet-operator-appeals-create'
                    },
                    {
                        path: 'appeals/update/:id',
                        component: () => import('@/pages/cabinet/operator/appeals/update/[id]'),
                        name: 'cabinet-operator-appeals-update'
                    },
                    {
                        path: 'appeals/review/:id',
                        component: () => import('@/pages/cabinet/operator/appeals/review/[id]'),
                        name: 'cabinet-operator-appeals-review'
                    },
                ]
            },
            {
                path: 'category',
                name: 'cabinet-category',
                children: [
                    {
                        path: 'appeals',
                        component: () => import('@/pages/cabinet/category/appeals'),
                        name: 'cabinet-category-appeals'
                    },
                    {
                        path: 'appeals/review/:id',
                        component: () => import('@/pages/cabinet/category/appeals/review/[id]'),
                        name: 'cabinet-category-appeals-review'
                    },
                ]
            },
            {
                path: 'ministry',
                name: 'cabinet-ministry',
                children: [
                    {
                        path: 'appeals',
                        component: () => import('@/pages/cabinet/ministry/appeals'),
                        name: 'cabinet-ministry-appeals'
                    },
                    {
                        path: 'appeals/review/:id',
                        component: () => import('@/pages/cabinet/ministry/appeals/review/[id]'),
                        name: 'cabinet-ministry-appeals-review'
                    },
                ]
            },
            {
                path: 'region',
                name: 'cabinet-region',
                children: [
                    {
                        path: 'appeals',
                        component: () => import('@/pages/cabinet/region/appeals'),
                        name: 'cabinet-region-appeals'
                    },
                    {
                        path: 'appeals/review/:id',
                        component: () => import('@/pages/cabinet/region/appeals/review/[id]'),
                        name: 'cabinet-region-appeals-review'
                    },
                ]
            },
            {
                path: 'district',
                name: 'cabinet-district',
                children: [
                    {
                        path: 'appeals',
                        component: () => import('@/pages/cabinet/district/appeals'),
                        name: 'cabinet-district-appeals'
                    },
                    {
                        path: 'appeals/review/:id',
                        component: () => import('@/pages/cabinet/district/appeals/review/[id]'),
                        name: 'cabinet-district-appeals-review'
                    },
                ]
            },
            {
                path: 'viewer',
                name: 'cabinet-viewer',
                children: [
                    {
                        path: 'appeals',
                        component: () => import('@/pages/cabinet/viewer/appeals'),
                        name: 'cabinet-viewer-appeals',
                    },
                    {
                        path: 'appeals/review/:id',
                        component: () => import('@/pages/cabinet/viewer/appeals/review/[id]'),
                        name: 'cabinet-viewer-appeals-review',
                    },
                    {
                        path: 'map',
                        component: () => import('@/pages/cabinet/viewer/map'),
                        name: 'cabinet-viewer-map'
                    },
                ]
            },
            {
                path: 'doc-admin',
                name: 'cabinet-doc-admin',
                children: [
                    {
                        path: 'docs',
                        component: () => import('@/pages/cabinet/doc-admin/docs'),
                        name: 'cabinet-doc-admin-docs'
                    },
                    {
                        path: 'docs/create',
                        component: () => import('@/pages/cabinet/doc-admin/docs/Create'),
                        name: 'cabinet-doc-admin-docs-create'
                    },
                    {
                        path: 'docs/update/:id',
                        component: () => import('@/pages/cabinet/doc-admin/docs/update/[id]'),
                        name: 'cabinet-doc-admin-docs-update'
                    },
                    {
                        path: 'docs/review/:id',
                        component: () => import('@/pages/cabinet/doc-admin/docs/review/[id]'),
                        name: 'cabinet-doc-admin-docs-review'
                    },
                    {
                        path: 'doc-types',
                        component: () => import('@/pages/cabinet/doc-admin/doc-types'),
                        name: 'cabinet-doc-admin-doc-types'
                    },
                ]
            },
        ]
    }
]

export default routes